import {
  Box,
  ButtonReset,
  MainNav as MgtMainNav,
  Stack,
  useMargaret,
} from '@tymate/margaret';
import { IcCog, IcDashboard, IcFile } from 'components/Icons';
import { useAuth } from 'hooks';
import logo from 'images/logo-white.svg';
import { IoLogOutOutline } from 'react-icons/io5';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FF_IMPORT_IS_ENABLED } from '../constants';

const MainNav = styled(MgtMainNav)`
  position: fixed;
  z-index: 3;
`;

const Logo = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: ${({ theme }) => theme.spacing(2)} 0;
`;

const LogoLink = styled(Link)``;

const SidebarNavLink = styled(Stack).attrs(({ as }) => ({
  as: as || NavLink,
  gutterSize: 1,
  alignY: 'center',
  size: 'full',
}))`
  ${({ theme }) => theme.fontStyles.bodySmall}

  color: rgba(255, 255, 255, 0.6);
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: background 150ms ease, color 150ms ease;

  &:hover,
  &.active {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(50, 74, 211, 0.5);
  }

  svg {
    font-size: 1.5rem;
  }
`;

const MainNavHeader = styled(MainNav.Header)`
  padding-top: ${({ theme }) => theme.spacing(0.25)};

  ${({ theme }) => theme.media.tablet`
    padding-top: ${({ theme }) => theme.spacing()};
    justify-content: center;
  `}
`;

const MainSidebar = () => {
  const { logout } = useAuth();
  const { isMobile } = useMargaret();

  return (
    <MainNav>
      <MainNavHeader size="full">
        {isMobile ? (
          <MainNav.Trigger style={{ padding: 0 }} />
        ) : (
          <LogoLink to="/">
            <Logo src={logo} alt="Cap recouvrement" />
          </LogoLink>
        )}
      </MainNavHeader>

      <MainNav.MainContent>
        <MainNav.Items gutterSize={0.25}>
          <MainNav.Item>
            <SidebarNavLink to="/dossiers">
              <Stack>
                <IcFile />
              </Stack>
              <span>Dossiers</span>
            </SidebarNavLink>
          </MainNav.Item>
          <MainNav.Item>
            <SidebarNavLink to="/tableau-de-bord" end>
              <Stack>
                <IcDashboard />
              </Stack>
              <span>Tableau de bord</span>
            </SidebarNavLink>
          </MainNav.Item>
          {/* <MainNav.Item>
          <SidebarNavLink to="/mon-entreprise">
            <Stack>
              <IcBuildings />
            </Stack>
            <span>Mon entreprise</span>
          </SidebarNavLink>
        </MainNav.Item> */}
          {FF_IMPORT_IS_ENABLED && (
            <MainNav.Item>
              <SidebarNavLink to="/parametres">
                <Stack>
                  <IcCog />
                </Stack>
                <span>Paramètres</span>
              </SidebarNavLink>
            </MainNav.Item>
          )}
          {/* <MainNav.Item>
          <SidebarNavLink to="/support">
            <Stack>
              <IcSupport />
            </Stack>
            <span>Support</span>
          </SidebarNavLink>
        </MainNav.Item> */}
          {isMobile && (
            <MainNav.Item>
              <SidebarNavLink as={ButtonReset} onClick={logout}>
                <Stack>
                  <IoLogOutOutline />
                </Stack>
                <span>Se déconnecter</span>
              </SidebarNavLink>
            </MainNav.Item>
          )}
        </MainNav.Items>
      </MainNav.MainContent>
      {!isMobile && (
        <Box padding={1} size="full">
          <SidebarNavLink as={ButtonReset} onClick={logout}>
            <Stack>
              <IoLogOutOutline />
            </Stack>
            <span>Se déconnecter</span>
          </SidebarNavLink>
        </Box>
      )}
    </MainNav>
  );
};

export default MainSidebar;
