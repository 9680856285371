import { Stack } from '@tymate/margaret';
import { useAuth } from 'hooks';
import { MdArrowDropDown, MdPowerSettingsNew } from 'react-icons/md';
import Dropdown from './Dropdown';

const UserDropdown = () => {
  const { logout, username } = useAuth();

  return (
    <Dropdown
      align="end"
      trigger={
        <Stack alignY="center" gutterSize={0.5}>
          <span>{username}</span>
          <Stack>
            <MdArrowDropDown />
          </Stack>
        </Stack>
      }
    >
      <Dropdown.MenuItem onClick={logout}>
        <Stack>
          <MdPowerSettingsNew size={22} />
        </Stack>
        <span>Déconnexion</span>
      </Dropdown.MenuItem>
    </Dropdown>
  );
};

export default UserDropdown;
